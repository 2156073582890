var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Users"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Users"}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search users..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('action-button',{staticClass:"button is-rounded",on:{"click":_vm.toggleStatusView}},[_vm._v(" "+_vm._s(_vm.filters.active ? 'Inactive' : 'Active')+" Users ")]),_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-user'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("User")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.users.data),function(user){return _c('div',{key:user.id,staticClass:"box is-marginless"},[_c('columns',{staticClass:"is-mobile"},[_c('column',{staticClass:"is-half-mobile"},[_c('router-link',{staticClass:"is-flex is-align-items-center",attrs:{"to":{
                  name: 'user-manager',
                  params: {
                    user: user.uuid
                  }
                }}},[_c('avatar',{staticClass:"mr-2",attrs:{"path":user.profile ? user.profile.picture_url : null,"name":user.full_name,"size":24}}),_vm._v(" "+_vm._s(user.full_name)+" "),(_vm.isAdmin(user.abilities))?_c('icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Administrator'),expression:"'Administrator'"}],staticClass:"has-text-warning",attrs:{"icon":"shield"}}):_vm._e()],1),_c('small',{staticClass:"has-text-grey"},[(user.designation)?_c('span',[_vm._v(" "+_vm._s(user.designation)+", ")]):_vm._e(),_vm._v(" "+_vm._s(user.email)),(user.profile && user.profile.mobile)?_c('span',[_vm._v(", "+_vm._s(user.profile.mobile))]):_vm._e(),(user.is_passive)?_c('span',{staticClass:"is-small tag is-warning ml-1"},[_vm._v("Passive")]):_vm._e()])],1),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[(user.latest_device)?_c('p',[_vm._v(_vm._s(user.latest_device.name)+" - "+_vm._s(user.latest_device.version))]):_vm._e()]),_c('column',{staticClass:"is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end"},[_c('span',{staticClass:"tag",class:{
                  'is-danger': !user.active,
                  'is-success': user.active,
                }},[_vm._v(" "+_vm._s(user.active ? 'Active User' : 'Inactive User')+" ")])])],1)],1)}),0),(_vm.users.data.length)?_c('pager',{attrs:{"pageable":_vm.users,"context":"User","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.users.data.length,"with-link":"","heading":"There are no users available for display.","to":{
          name: 'create-user'
        },"to-text":"New User"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }